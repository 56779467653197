import graphql__37735d24327cd0f94ced from "./__generated__/UserSync_viewer.graphql.ts";
import graphql__9821f9894aee141cc869 from "./__generated__/UserSyncQuery.graphql.ts";
import graphql__b056313ee20e1fe8bd3d from "./__generated__/UserSyncMutation.graphql.ts";
import graphql__c752364d43583d3fdd42 from "./__generated__/UserSyncLoginUserMutation.graphql.ts";/* eslint-disable relay/unused-fields */
import { User } from '@auth0/auth0-react'
import { Alert } from '@portal/components'
import * as Sentry from '@sentry/browser'
import graphql from 'babel-plugin-relay/macro'
import { useCreateHubSpotContact, useTrackContactVisit } from 'components/hooks'
import { MainLayout } from 'components/layout'
import { FullPageLoader } from 'components/loader'
import React, { useEffect, useState } from 'react'
import {
  useLazyLoadQuery,
  useMutation,
  useRefetchableFragment,
} from 'react-relay/hooks'
import { useAnalytics } from 'utils/analytics'

import { UserSync_viewer$key } from './__generated__/UserSync_viewer.graphql'
import { UserSyncLoginUserMutation } from './__generated__/UserSyncLoginUserMutation.graphql'
import { UserSyncMutation } from './__generated__/UserSyncMutation.graphql'
import { UserSyncQuery } from './__generated__/UserSyncQuery.graphql'
import { UserSyncQueryRefetch } from './__generated__/UserSyncQueryRefetch.graphql'
import { useInitLD } from './useInitLD'

const CurrentUserFragment = graphql__37735d24327cd0f94ced

const query = graphql__9821f9894aee141cc869

export const CreateUnknownUserMutation = graphql__b056313ee20e1fe8bd3d

export const LoginUserMutation = graphql__c752364d43583d3fdd42

type Props = {
  /** User data extracted from auth0. */
  user: User
  /** Children. */
  children: (
    userInfo: {
      readonly id: string
      readonly email: string
      readonly isSuperAdmin: boolean
    } | null,
  ) => React.ReactNode
}

/** Component used to sync the current user data to the portal
 * when a user with a valid JWT token doesn't exist on the server.
 *
 * This ensures that all users authenticated through the Auth provider
 * have been saved on our server.
 */
export default function UserSync({ user, children }: Props) {
  const { setUserId, logEvent } = useAnalytics()
  const [didRefetchViewer, setDidRefetchViewer] = useState(false)
  const { viewer } = useLazyLoadQuery<UserSyncQuery>(query, {})
  const [data, refetch] = useRefetchableFragment<
    UserSyncQueryRefetch,
    UserSync_viewer$key
  >(CurrentUserFragment, viewer)
  const [createUser] = useMutation<UserSyncMutation>(CreateUnknownUserMutation)
  const [loginUser] = useMutation<UserSyncLoginUserMutation>(LoginUserMutation)
  const viewerId = data?.me?.id
  const currentEmail = data?.me?.email
  const currentName = user.name
  const hubspotContactId = useCreateHubSpotContact(viewerId)
  useTrackContactVisit(hubspotContactId)
  useInitLD({
    key: viewerId,
    email: currentEmail,
    name: currentName,
    isRunningCypress: window.Cypress !== undefined,
  })

  useEffect(() => {
    if (viewerId && currentEmail) {
      setUserId(viewerId, currentEmail)

      Sentry.setUser({ id: viewerId, email: currentEmail })
    }
  }, [viewerId, currentEmail, setUserId])

  useEffect(() => {
    const props = (logEventType: string) => ({
      onCompleted() {
        logEvent(logEventType)
        refetch(
          {},
          {
            fetchPolicy: 'network-only',
            onComplete: () => {
              setDidRefetchViewer(true)
            },
          },
        )
      },
    })

    if (!viewerId && user) {
      createUser({
        variables: {
          input: {
            auth0Id: user.sub!,
            firstName: user.given_name,
            lastName: user.family_name,
            email: user.email!,
            imageUrl: user.picture,
          },
        },
        ...props('createdAccount'),
      })
    } else if (viewerId) {
      logEvent('userLogin')
    }
  }, [viewerId, createUser, user, refetch, logEvent])

  useEffect(() => {
    if (viewerId) {
      loginUser({ variables: {} })
    }
  }, [viewerId, loginUser])

  if (!viewerId) {
    return didRefetchViewer ? (
      <MainLayout>
        <Alert variant="danger">
          There was an error starting the portal session.
        </Alert>
      </MainLayout>
    ) : (
      <FullPageLoader />
    )
  }

  // Can't return children directly
  // https://github.com/DefinitelyTyped/DefinitelyTyped/issues/18051#issuecomment-449628575
  return <>{children(data?.me)}</>
}
