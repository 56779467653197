import graphql__9fba1ef69a3255026521 from "./__generated__/NavigationBarRouterQuery.graphql.ts";import graphql from 'babel-plugin-relay/macro'
import NavigationBar from 'components/navigationBar'
import { Suspense } from 'react'
import { useLazyLoadQuery } from 'react-relay/hooks'
import { Route, Routes } from 'react-router-dom'

import { NavigationBarRouterQuery } from './__generated__/NavigationBarRouterQuery.graphql'

const query = graphql__9fba1ef69a3255026521

/**
 * NavigationBar router to control when the navigation bar should be rendered. Routes explicitly
 * created with an empty component will hide the navigation bar. As a fallback the navigation bar
 * component is rendered for all routes that aren't explicitly defined.
 */
const NavigationBarRouter = () => {
  const { viewer } = useLazyLoadQuery<NavigationBarRouterQuery>(
    query,
    {},
    { fetchPolicy: 'store-and-network' },
  )
  const viewerHasAtLeastOneApp =
    !!viewer.me!.apps.length ||
    (!!viewer.organizations.totalCount &&
      !!viewer.organizations.edges?.length &&
      !!viewer.organizations.edges.reduce(
        (totalApps, edge) => totalApps + (edge?.node.apps.length || 0),
        0,
      ))
  const viewerIsInAtLeastOneOrganization = !!viewer.organizations.totalCount

  const isSuperAdmin = !!viewer.me?.isSuperAdmin
  // A user who is a member in at least one organization should have the option to switch between
  // tenants
  const showLinks =
    isSuperAdmin || viewerHasAtLeastOneApp || viewerIsInAtLeastOneOrganization

  return (
    <Suspense fallback={null}>
      <Routes>
        <Route path="/data-browser/*" element={null} />
        <Route path="/dql-editor/*" element={null} />
        <Route path="/forge/*" element={null} />
        <Route path="/*" element={<NavigationBar showLinks={showLinks} />} />
      </Routes>
    </Suspense>
  )
}

export default NavigationBarRouter
